import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export default function Loading({ string }) {
  const classes = useStyles();
  const array = string.split('');

  return (
    <>
      {array.map(item => (
        <Card key={String(item)} className={classes.root}>
          <CardHeader
              avatar={
              <Skeleton animation="wave" variant="circle" width={40} height={40} />
              }
              title={
                <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
              }
              subheader={<Skeleton animation="wave" height={10} width="40%" />}
              />
          <Skeleton animation="wave" variant="rect" className={classes.media} />
          <CardContent>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="94%" />
          </CardContent>
        </Card>
      ))}
    </>
  );
}

Loading.propTypes = {
  string: PropTypes.string.isRequired,
};
